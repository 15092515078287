@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Oleo+Script:400|Open+Sans:300,300italic,600,600italic,800");

/*
	Verti by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), url("images/bg01.png");
		background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), url("images/bg01.png");
		background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), url("images/bg01.png");
		background-image: linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), url("images/bg01.png");
		background-color: #f7f7f7;
		background-position: top left, top left;
		background-repeat: repeat-x, repeat;
		background-size: 100% 30em, auto;
		font-family: 'Open Sans', sans-serif;
		font-size: 13pt;
		color: #696969;
		font-weight: 300;
		line-height: 2.25em;
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

	input, select, textarea {
		font-family: 'Open Sans', sans-serif;
		font-size: 13pt;
		color: #696969;
		font-weight: 300;
		line-height: 2.25em;
	}

	a {
		color: #DD555F;
		text-decoration: underline;
	}

		a:hover {
			text-decoration: none;
		}

	h1, h2, h3, h4, h5, h6 {
		color: #444;
		font-weight: 800;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
			outline: 0;
		}

	h2 {
		font-size: 2.25em;
		margin: 0 0 1.1em 0;
		line-height: 1em;
	}

	h3 {
		font-size: 1.35em;
		margin: 0 0 1em 0;
	}

	b, strong {
		font-weight: 600;
		color: #444;
	}

	i, em {
		font-style: italic;
	}

	br.clear {
		clear: both;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	hr {
		border: 0;
		border-top: solid 1px #eee;
	}

	blockquote {
		border-left: solid 0.5em #eee;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	p, ul, ol, dl, table {
		margin-bottom: 2em;
	}

/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 1400px;
	}

		@media screen and (max-width: 1680px) {

			.container {
				width: 1200px;
			}

		}

		@media screen and (max-width: 1280px) {

			.container {
				width: 960px;
			}

		}

		@media screen and (max-width: 980px) {

			.container {
				width: 90%;
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				width: 100%;
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

			.row.gtr-0 > * {
				padding: 0px 0 0 0px;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0px;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0px;
				}

		.row.gtr-25 {
			margin-top: -10px;
			margin-left: -10px;
		}

			.row.gtr-25 > * {
				padding: 10px 0 0 10px;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -10px;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 10px;
				}

		.row.gtr-50 {
			margin-top: -20px;
			margin-left: -20px;
		}

			.row.gtr-50 > * {
				padding: 20px 0 0 20px;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -20px;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 20px;
				}

		.row {
			margin-top: -40px;
			margin-left: -40px;
		}

			.row > * {
				padding: 40px 0 0 40px;
			}

			.row.gtr-uniform {
				margin-top: -40px;
			}

				.row.gtr-uniform > * {
					padding-top: 40px;
				}

		.row.gtr-150 {
			margin-top: -60px;
			margin-left: -60px;
		}

			.row.gtr-150 > * {
				padding: 60px 0 0 60px;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -60px;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 60px;
				}

		.row.gtr-200 {
			margin-top: -80px;
			margin-left: -80px;
		}

			.row.gtr-200 > * {
				padding: 80px 0 0 80px;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -80px;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 80px;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.33333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.33333%;
				}

				.row > .col-2-xlarge {
					width: 16.66667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.66667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.33333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.33333%;
				}

				.row > .col-5-xlarge {
					width: 41.66667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.66667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.33333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.33333%;
				}

				.row > .col-8-xlarge {
					width: 66.66667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.66667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.33333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.33333%;
				}

				.row > .col-11-xlarge {
					width: 91.66667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.66667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-25 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 10px;
						}

				.row.gtr-50 {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row.gtr-50 > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 20px;
						}

				.row {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-uniform > * {
							padding-top: 40px;
						}

				.row.gtr-150 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-150 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 60px;
						}

				.row.gtr-200 {
					margin-top: -80px;
					margin-left: -80px;
				}

					.row.gtr-200 > * {
						padding: 80px 0 0 80px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -80px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 80px;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-large {
					order: -1;
				}

				.row > .col-1-large {
					width: 8.33333%;
				}

				.row > .off-1-large {
					margin-left: 8.33333%;
				}

				.row > .col-2-large {
					width: 16.66667%;
				}

				.row > .off-2-large {
					margin-left: 16.66667%;
				}

				.row > .col-3-large {
					width: 25%;
				}

				.row > .off-3-large {
					margin-left: 25%;
				}

				.row > .col-4-large {
					width: 33.33333%;
				}

				.row > .off-4-large {
					margin-left: 33.33333%;
				}

				.row > .col-5-large {
					width: 41.66667%;
				}

				.row > .off-5-large {
					margin-left: 41.66667%;
				}

				.row > .col-6-large {
					width: 50%;
				}

				.row > .off-6-large {
					margin-left: 50%;
				}

				.row > .col-7-large {
					width: 58.33333%;
				}

				.row > .off-7-large {
					margin-left: 58.33333%;
				}

				.row > .col-8-large {
					width: 66.66667%;
				}

				.row > .off-8-large {
					margin-left: 66.66667%;
				}

				.row > .col-9-large {
					width: 75%;
				}

				.row > .off-9-large {
					margin-left: 75%;
				}

				.row > .col-10-large {
					width: 83.33333%;
				}

				.row > .off-10-large {
					margin-left: 83.33333%;
				}

				.row > .col-11-large {
					width: 91.66667%;
				}

				.row > .off-11-large {
					margin-left: 91.66667%;
				}

				.row > .col-12-large {
					width: 100%;
				}

				.row > .off-12-large {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -6.25px;
					margin-left: -6.25px;
				}

					.row.gtr-25 > * {
						padding: 6.25px 0 0 6.25px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -6.25px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 6.25px;
						}

				.row.gtr-50 {
					margin-top: -12.5px;
					margin-left: -12.5px;
				}

					.row.gtr-50 > * {
						padding: 12.5px 0 0 12.5px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -12.5px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 12.5px;
						}

				.row {
					margin-top: -25px;
					margin-left: -25px;
				}

					.row > * {
						padding: 25px 0 0 25px;
					}

					.row.gtr-uniform {
						margin-top: -25px;
					}

						.row.gtr-uniform > * {
							padding-top: 25px;
						}

				.row.gtr-150 {
					margin-top: -37.5px;
					margin-left: -37.5px;
				}

					.row.gtr-150 > * {
						padding: 37.5px 0 0 37.5px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -37.5px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 37.5px;
						}

				.row.gtr-200 {
					margin-top: -50px;
					margin-left: -50px;
				}

					.row.gtr-200 > * {
						padding: 50px 0 0 50px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -50px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 50px;
						}

		}

		@media screen and (max-width: 980px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-medium {
					order: -1;
				}

				.row > .col-1-medium {
					width: 8.33333%;
				}

				.row > .off-1-medium {
					margin-left: 8.33333%;
				}

				.row > .col-2-medium {
					width: 16.66667%;
				}

				.row > .off-2-medium {
					margin-left: 16.66667%;
				}

				.row > .col-3-medium {
					width: 25%;
				}

				.row > .off-3-medium {
					margin-left: 25%;
				}

				.row > .col-4-medium {
					width: 33.33333%;
				}

				.row > .off-4-medium {
					margin-left: 33.33333%;
				}

				.row > .col-5-medium {
					width: 41.66667%;
				}

				.row > .off-5-medium {
					margin-left: 41.66667%;
				}

				.row > .col-6-medium {
					width: 50%;
				}

				.row > .off-6-medium {
					margin-left: 50%;
				}

				.row > .col-7-medium {
					width: 58.33333%;
				}

				.row > .off-7-medium {
					margin-left: 58.33333%;
				}

				.row > .col-8-medium {
					width: 66.66667%;
				}

				.row > .off-8-medium {
					margin-left: 66.66667%;
				}

				.row > .col-9-medium {
					width: 75%;
				}

				.row > .off-9-medium {
					margin-left: 75%;
				}

				.row > .col-10-medium {
					width: 83.33333%;
				}

				.row > .off-10-medium {
					margin-left: 83.33333%;
				}

				.row > .col-11-medium {
					width: 91.66667%;
				}

				.row > .off-11-medium {
					margin-left: 91.66667%;
				}

				.row > .col-12-medium {
					width: 100%;
				}

				.row > .off-12-medium {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -6.25px;
					margin-left: -6.25px;
				}

					.row.gtr-25 > * {
						padding: 6.25px 0 0 6.25px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -6.25px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 6.25px;
						}

				.row.gtr-50 {
					margin-top: -12.5px;
					margin-left: -12.5px;
				}

					.row.gtr-50 > * {
						padding: 12.5px 0 0 12.5px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -12.5px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 12.5px;
						}

				.row {
					margin-top: -25px;
					margin-left: -25px;
				}

					.row > * {
						padding: 25px 0 0 25px;
					}

					.row.gtr-uniform {
						margin-top: -25px;
					}

						.row.gtr-uniform > * {
							padding-top: 25px;
						}

				.row.gtr-150 {
					margin-top: -37.5px;
					margin-left: -37.5px;
				}

					.row.gtr-150 > * {
						padding: 37.5px 0 0 37.5px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -37.5px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 37.5px;
						}

				.row.gtr-200 {
					margin-top: -50px;
					margin-left: -50px;
				}

					.row.gtr-200 > * {
						padding: 50px 0 0 50px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -50px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 50px;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-small {
					order: -1;
				}

				.row > .col-1-small {
					width: 8.33333%;
				}

				.row > .off-1-small {
					margin-left: 8.33333%;
				}

				.row > .col-2-small {
					width: 16.66667%;
				}

				.row > .off-2-small {
					margin-left: 16.66667%;
				}

				.row > .col-3-small {
					width: 25%;
				}

				.row > .off-3-small {
					margin-left: 25%;
				}

				.row > .col-4-small {
					width: 33.33333%;
				}

				.row > .off-4-small {
					margin-left: 33.33333%;
				}

				.row > .col-5-small {
					width: 41.66667%;
				}

				.row > .off-5-small {
					margin-left: 41.66667%;
				}

				.row > .col-6-small {
					width: 50%;
				}

				.row > .off-6-small {
					margin-left: 50%;
				}

				.row > .col-7-small {
					width: 58.33333%;
				}

				.row > .off-7-small {
					margin-left: 58.33333%;
				}

				.row > .col-8-small {
					width: 66.66667%;
				}

				.row > .off-8-small {
					margin-left: 66.66667%;
				}

				.row > .col-9-small {
					width: 75%;
				}

				.row > .off-9-small {
					margin-left: 75%;
				}

				.row > .col-10-small {
					width: 83.33333%;
				}

				.row > .off-10-small {
					margin-left: 83.33333%;
				}

				.row > .col-11-small {
					width: 91.66667%;
				}

				.row > .off-11-small {
					margin-left: 91.66667%;
				}

				.row > .col-12-small {
					width: 100%;
				}

				.row > .off-12-small {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}

		}

/* Section/Article */

	section, article {
		margin-bottom: 5em;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}

	header {
		margin: 0 0 2em 0;
	}

		header h2, header h3 {
			margin: 0 0 0.25em 0;
		}

		header p {
			margin: 0;
		}

	footer {
		margin: 2.5em 0 0 0;
	}

/* Forms */

	form label {
		display: block;
		font-weight: 800;
		margin: 0 0 1em 0;
		font-size: 0.8em;
		color: #444;
	}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select,
	form textarea {
		-webkit-appearance: none;
		display: block;
		width: 100%;
		border-radius: 8px;
		border: solid 1px #eee;
	}

		form input[type="text"]:focus,
		form input[type="email"]:focus,
		form input[type="password"]:focus,
		form select:focus,
		form textarea:focus {
			border-color: #DD555F;
		}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"] {
		line-height: 3em;
		padding: 0 1em;
	}

	form select {
		line-height: 3em;
		padding: 0 1em;
	}

	form textarea {
		min-height: 9em;
		padding: 1em;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Tables */

	table {
		width: 100%;
	}

		table tr {
			border-top: solid 1px #eee;
		}

			table tr:first-child {
				border-top: 0;
			}

		table td {
			padding: 0.5em 1em 0.5em 1em;
		}

		table th {
			text-align: left;
			padding: 0.5em 1em 0.5em 1em;
			font-weight: 800;
			margin: 0 0 1em 0;
		}

		table thead {
			background: #404248;
			color: #fff;
		}

/* Image */

	.image {
		display: inline-block;
		outline: 0;
	}

		.image img {
			display: block;
			width: 100%;
			border-radius: 8px;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 2.5em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 2.5em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		-moz-transition: background-color .25s ease-in-out;
		-webkit-transition: background-color .25s ease-in-out;
		-ms-transition: background-color .25s ease-in-out;
		transition: background-color .25s ease-in-out;
		-webkit-appearance: none;
		position: relative;
		display: inline-block;
		background: #DD555F;
		color: #fff;
		text-decoration: none;
		border-radius: 6px;
		font-weight: 800;
		outline: 0;
		border: 0;
		cursor: pointer;
		font-size: 1.35em;
		padding: 0.6em 1.5em;
	}

		input[type="button"].icon:before,
		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		button.icon:before,
		.button.icon:before {
			position: relative;
			margin-right: 0.5em;
			top: 0.05em;
		}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			background: #ED656F;
		}

		input[type="button"]:active,
		input[type="submit"]:active,
		input[type="reset"]:active,
		button:active,
		.button:active {
			background: #FD757F;
		}

		input[type="button"].alt,
		input[type="submit"].alt,
		input[type="reset"].alt,
		button.alt,
		.button.alt {
			background: #f1f1f1;
			background: rgba(0, 0, 0, 0.05);
			color: #444;
		}

			input[type="button"].alt:hover,
			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			button.alt:hover,
			.button.alt:hover {
				background: #e8e8e8;
				background: rgba(0, 0, 0, 0.085);
			}

			input[type="button"].alt:active,
			input[type="submit"].alt:active,
			input[type="reset"].alt:active,
			button.alt:active,
			.button.alt:active {
				background: #e4e4e4;
				background: rgba(0, 0, 0, 0.15);
			}

		input[type="button"].large,
		input[type="submit"].large,
		input[type="reset"].large,
		button.large,
		.button.large {
			font-size: 2.25em;
			padding: 0.8em 0.9em;
		}

/* List */

	ul {
		list-style: disc;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.style2 {
			list-style: none;
			padding-left: 0;
		}

			ul.style2 li {
				padding-left: 0;
			}

	ol {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

/* Actions */

	ul.actions {
		list-style: none;
		padding-left: 0;
	}

		ul.actions li {
			display: inline-block;
			padding: 0 0 0 0.25em;
			margin: 0 0 0 0.25em;
		}

			ul.actions li:first-child {
				margin-left: 0;
				padding-left: 0;
			}

/* Menu */

	ul.menu {
		list-style: none;
		padding-left: 0;
	}

		ul.menu li {
			border-left: solid 1px #e0e0e0;
			display: inline-block;
			padding: 0 0 0 0.75em;
			margin: 0 0 0 0.75em;
		}

			ul.menu li:first-child {
				border-left: 0;
				margin-left: 0;
				padding-left: 0;
			}

/* Box */

	.box {
		background: #fff;
		border-radius: 6px;
		box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
		padding: 2em;
	}

		.box.feature {
			padding: 0;
		}

			.box.feature .inner {
				padding: 4em 2em 3em 2em;
			}

			.box.feature h2 {
				font-size: 1.35em;
			}

			.box.feature p {
				margin: 0;
			}

			.box.feature .image {
				position: relative;
				margin: 0;
			}

				.box.feature .image img {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

/* Icons */

	.icon {
		text-decoration: none;
		position: relative;
		text-decoration: none;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon:before {
			line-height: inherit;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

		.icon > .label {
			display: none;
		}

/* Widgets */

	.widget.thumbnails .grid {
		margin: 0 0 3em 0;
	}

	.widget.thumbnails .image {
		margin: 0;
	}

	.widget.contact ul {
		list-style: none;
		padding-left: 0;
		overflow: hidden;
		position: relative;
		left: -4px;
		top: 4px;
		margin-bottom: 1.5em;
	}

		.widget.contact ul li {
			display: inline-block;
			margin-right: 0.25em;
			padding-left: 0;
		}

			.widget.contact ul li a {
				-moz-transition: background-color .25s ease-in-out;
				-webkit-transition: background-color .25s ease-in-out;
				-ms-transition: background-color .25s ease-in-out;
				transition: background-color .25s ease-in-out;
				display: inline-block;
				width: 2.5em;
				height: 2.5em;
				text-align: center;
				line-height: 2.5em;
				border-radius: 0.35em;
				outline: 0;
				opacity: 0.75;
				text-decoration: none;
				background: #222222;
			}

				.widget.contact ul li a:before {
					color: #f3f3f3;
					font-size: 1.5em;
					line-height: inherit;
				}

				.widget.contact ul li a:hover {
					background: #444444;
				}

				.widget.contact ul li a:active {
					background: #666666;
				}

/* Wrappers */

	#header-wrapper {
		padding: 4.5em 0 3em 0;
	}

	#features-wrapper {
		padding: 3em 0 3em 0;
	}

	#main-wrapper {
		background: #fff;
		box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
		padding: 5em 0 5em 0;
	}

	#footer-wrapper {
		padding: 6em 0 8em 0;
	}

/* Header */

	#header {
		position: relative;
	}

/* Logo */

	#logo {
		display: inline-block;
	}

		#logo h1 {
			background: #444444;
			color: #fff;
			border-radius: 6px;
			font-family: 'Oleo Script', serif;
			font-weight: 400;
			padding: 0.25em 0.2em 0.25em 0.2em;
			font-size: 3.25em;
			letter-spacing: 0.05em;
			display: inline-block;
			vertical-align: middle;
		}

		#logo span {
			font-weight: 800;
			line-height: 4.5em;
			letter-spacing: 0.025em;
			font-size: 0.9em;
			vertical-align: middle;
			margin: 0 0 0 1.25em;
		}

/* Nav */

	#nav {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 0.9em;
	}

		#nav ul {
			list-style: none;
			padding-left: 0;
		}

			#nav ul li {
				float: left;
				line-height: 4.5em;
				padding-left: 1.5em;
			}

				#nav ul li a, #nav ul li span {
					-moz-transition: background-color .25s ease-in-out;
					-webkit-transition: background-color .25s ease-in-out;
					-ms-transition: background-color .25s ease-in-out;
					transition: background-color .25s ease-in-out;
					font-weight: 800;
					letter-spacing: 0.025em;
					color: #696969;
					text-decoration: none;
					border-radius: 6px;
					padding: 0.5em 1em 0.5em 1em;
				}

				#nav ul li > ul {
					display: none;
				}

				#nav ul li:hover a, #nav ul li:hover span {
					background: #fff;
				}

				#nav ul li.active a, #nav ul li.active span {
					background: #fff;
				}

				#nav ul li.current a {
					background: #444;
					color: #fff;
				}

	.dropotron {
		background: #fff;
		letter-spacing: 0.025em;
		color: #696969;
		text-decoration: none;
		border-radius: 6px;
		padding: 0.75em 0;
		min-width: 15em;
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0.25em 0.25em 0.1em rgba(0, 0, 0, 0.025);
		margin-top: calc(-0.75em + 1px);
		margin-left: -1px;
		list-style: none;
		padding-left: 0;
	}

		.dropotron li {
			border-top: solid 1px #eee;
			line-height: 3em;
			padding-left: 0;
		}

			.dropotron li:first-child {
				border-top: 0;
			}

			.dropotron li > a, .dropotron li > span {
				-moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
				-webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
				-ms-transition: color .25s ease-in-out, background-color .25s ease-in-out;
				transition: color .25s ease-in-out, background-color .25s ease-in-out;
				text-decoration: none;
				color: inherit;
				display: block;
				padding: 0 1.5em;
			}

				.dropotron li > a:hover, .dropotron li > span:hover {
					background: #eee;
				}

			.dropotron li.active > a, .dropotron li.active > span {
				background: #eee;
			}

		.dropotron.level-0 {
			font-size: 0.8em;
			margin-left: 1.5em;
			margin-top: 0;
		}

			.dropotron.level-0:before {
				content: '';
				position: absolute;
				top: -0.5em;
				left: 1em;
				border-bottom: solid 0.75em #ffffff;
				border-left: solid 0.75em transparent;
				border-right: solid 0.75em transparent;
				z-index: 1;
			}

/* Banner */

	#banner {
		position: relative;
		padding: 4.5em;
	}

		#banner h2 {
			font-size: 3.5em;
			margin: 0.1em 0 0.35em 0;
		}

		#banner p {
			font-size: 2.75em;
			line-height: 1.35em;
			margin: 0;
		}

		#banner .button {
			width: 100%;
			margin-bottom: 0.5em;
		}

			#banner .button.icon:before {
				position: absolute;
				right: 0.15em;
				top: 50%;
				margin-top: -0.5em;
			}

			#banner .button.alt {
				margin-bottom: 0;
			}

		#banner ul {
			list-style: none;
			padding-left: 0;
			margin: 0 0 0 2em;
		}

			#banner ul li {
				padding-left: 0;
			}

/* Footer */

	#footer {
		margin-top: 0;
	}

		#footer a {
			color: inherit;
		}

	#copyright {
		color: #aaa;
		padding: 4em 0 0 0;
		text-align: center;
	}

		#copyright a {
			color: inherit;
		}

/* XLarge */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 12pt;
			}

	}

/* Large */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
			}

	}

/* Medium */

	#navPanel, #navToggle {
		display: none;
	}

	@media screen and (max-width: 980px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* Section/Article */

			section, article {
				clear: both;
				margin-bottom: 3em !important;
			}

			section.last, article.last {
				margin-bottom: 0 !important;
			}

		/* Image */

			.image.left {
				width: 25%;
				margin-bottom: 2em !important;
			}

		/* Menu */

			ul.menu li {
				display: block;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
			}

		/* Actions */

			ul.actions li {
				display: block;
				padding: 0;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				width: 100%;
				text-align: center;
				font-size: 1.35em;
				padding: 0.85em 0 !important;
			}

				input[type="button"].icon,
				input[type="submit"].icon,
				input[type="reset"].icon,
				button.icon,
				.button.icon {
					padding-right: 0.75em !important;
				}

					input[type="button"].icon:before,
					input[type="submit"].icon:before,
					input[type="reset"].icon:before,
					button.icon:before,
					.button.icon:before {
						margin-right: 0.5em;
						top: 0.05em;
					}

		/* Wrappers */

			#header-wrapper {
				padding: 3em 0 1em 0;
				text-align: center;
			}

			#footer-wrapper {
				padding: 6em 0 4em 0;
			}

		/* Header */

			#header {
				margin: 0 auto;
			}

		/* Logo */

			#logo span {
				display: block;
				margin: 0;
			}

		/* Nav */

			#nav {
				display: none;
			}

		/* Banner */

			#banner {
				padding: 4.5em 3em;
				text-align: center;
			}

				#banner ul {
					margin: 2em 0 0 0;
				}

				#banner .button.icon:before {
					position: relative;
					right: auto;
					top: auto;
				}

		/* Content */

			#content {
				border-bottom: solid 1px #e8e8e8;
				padding-bottom: 4em;
				margin-bottom: 0 !important;
			}

				#content > :last-child {
					margin-bottom: 0 !important;
				}

			#sidebar > :last-child {
				margin-bottom: 0 !important;
			}

		/* Footer */

			#copyright {
				padding: 0;
			}

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#navToggle {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 10001;
			}

				#navToggle .toggle {
					text-decoration: none;
					width: 70px;
					height: 50px;
					opacity: 0.35;
				}

					#navToggle .toggle:before {
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						text-transform: none !important;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
					}

					#navToggle .toggle:before {
						display: inline-block;
						text-decoration: none;
						position: absolute;
						left: 6px;
						top: 6px;
						background: rgba(0, 0, 0, 0.5);
						width: 58px;
						height: 38px;
						border-radius: 6px;
						content: '\f0c9';
						color: #fff;
						text-align: center;
						line-height: 38px;
						font-size: 20px;
					}

					#navToggle .toggle:active {
						opacity: 0.75;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateX(-275px);
				-webkit-transform: translateX(-275px);
				-ms-transform: translateX(-275px);
				transform: translateX(-275px);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 275px;
				z-index: 10002;
				background: #fff;
				box-shadow: inset -3px 0px 0px 0px #dfdfdf;
				padding: 0 23px 0 20px;
			}

				#navPanel .link {
					display: block;
					text-decoration: none;
					height: 54px;
					line-height: 54px;
					border-top: solid 1px #e8e8e8;
					color: #696969;
				}

					#navPanel .link:first-child {
						border-top: 0;
					}

				#navPanel .indent-1 {
					display: inline-block;
					width: 1em;
				}

				#navPanel .indent-2 {
					display: inline-block;
					width: 2em;
				}

				#navPanel .indent-3 {
					display: inline-block;
					width: 3em;
				}

				#navPanel .indent-4 {
					display: inline-block;
					width: 4em;
				}

				#navPanel .indent-5 {
					display: inline-block;
					width: 5em;
				}

				#navPanel .depth-0 {
					color: #444;
					font-weight: 800;
				}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navToggle {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateX(0);
				-webkit-transform: translateX(0);
				-ms-transform: translateX(0);
				transform: translateX(0);
			}

	}

/* Small */

	@media screen and (max-width: 736px) {

		/* Basic */

			body, input, select, textarea {
				line-height: 2em;
			}

			h2, h3, h4, h5, h6 {
				font-size: 13pt;
				margin: 0 0 1em 0;
			}

		/* Section/Article */

			section, article {
				clear: both;
				margin-bottom: 3em !important;
			}

				section.last, article.last {
					margin-bottom: 0 !important;
				}

		/* Image */

			.image.left {
				width: 25%;
				margin-bottom: 2em !important;
			}

		/* Menu */

			ul.menu li {
				display: block;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
			}

		/* Actions */

			ul.actions li {
				display: block;
				padding: 0;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				width: 100%;
				text-align: center;
				font-size: 1.25em !important;
				padding: 0.65em 0 !important;
			}

				input[type="button"].icon,
				input[type="submit"].icon,
				input[type="reset"].icon,
				button.icon,
				.button.icon {
					padding-right: 0.75em !important;
				}

					input[type="button"].icon:before,
					input[type="submit"].icon:before,
					input[type="reset"].icon:before,
					button.icon:before,
					.button.icon:before {
						margin-right: 0.5em;
						top: 0.05em;
					}

		/* Box */

			.box {
				padding: 15px 15px 15px 15px;
				margin: 0;
			}

				.box.feature {
					margin: 20px 0 0 0 !important;
					padding: 0;
				}

					.box.feature .inner {
						padding: 30px 15px 30px 15px;
					}

					.box.feature p {
						margin: 0;
					}

					.box.feature .image {
						position: relative;
						margin: 0;
					}

						.box.feature .image img {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
						}

		/* Widgets */

			.widget.thumbnails .image {
				margin: 0;
			}

			.widget.thumbnails .x {
				padding: 1em 0.5em 0 0;
			}

			.widget.thumbnails .y {
				padding: 1em 0 0 0.5em;
			}

			.widget.thumbnails .row:first-child .x, .widget.thumbnails .row:first-child .y {
				padding-top: 0;
			}

		/* Wrappers */

			#header-wrapper {
				padding: 3em 0 1.5em 0;
			}

			#banner-wrapper {
				padding: 0 15px 0 15px;
			}

			#features-wrapper {
				padding: 0 15px 40px 15px;
			}

			#main-wrapper, #footer-wrapper {
				padding: 40px 30px 40px 30px;
			}

		/* Logo */

			#logo h1 {
				font-size: 2.75em;
				padding: 0.2em 0.275em;
			}

			#logo span {
				line-height: 3em;
			}

		/* Banner */

			#banner {
				text-align: center;
				padding: 15px 15px 15px 15px;
				margin: 0 auto !important;
			}

				#banner h2 {
					font-size: 2em;
					margin: 0.75em 0 0.5em 0;
				}

				#banner p {
					font-size: 1.5em;
					line-height: 1.5em;
					margin: 0;
				}

				#banner ul {
					margin: 0;
				}

				#banner .button {
					margin: 0 0 1em 0;
				}

		/* Content */

			#content {
				padding-bottom: 3em;
			}

		/* Footer */

			#footer section {
				border-bottom: solid 1px #ccc;
				padding-bottom: 3em;
				margin-bottom: 1em !important;
			}

			#copyright {
				text-align: left;
			}

	}